// REACT
import React from "react"
// MUI
import { Grid, Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"

// COMPONENT FUNCTION
const MooringsPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      wellfleetMooring: file(relativePath: { eq: "wellfleet-mooring.jpg" }) {
        ...BsmFluidImage
      }
      mooringBarge: file(relativePath: { eq: "mooring-barge.jpg" }) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Moorings">
      <PageTitle title="MOORINGS" />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.wellfleetMooring.childImageSharp.fluid}
          caption="Two boats on moorings in Wellfleet Harbor"
          displayCaption
        />
      </ImageBlock>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={7}>
          <Typography variant="h6" component="h2" gutterBottom>
            Mooring Service
          </Typography>
          <Typography paragraph>
            Through our Small Boat Service unit we offer a full array of mooring
            services. We will pull your mooring out in the fall, make any
            necessary repairs, store it for the winter, and launch it again in
            the spring. We provide mooring service for Wellfleet and portions of
            Eastham.
          </Typography>
          <Typography paragraph>
            Our mooring barge (pictured), allows us to install customer moorings
            with ease and efficiency.
          </Typography>
          <Typography variant="h6" component="h2" gutterBottom>
            Mooring Sales
          </Typography>
          <Typography paragraph>
            Beginning with a correctly sized mushroom anchor, we will build you
            a strong and reliable mooring. We use stainless steel hardware and
            durable line. Other types of moorings are available at your request.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <ImageWithCaption
            fluid={data.mooringBarge.childImageSharp.fluid}
            caption="Bay Sails Marine mooring barge"
            displayCaption
            maxWidth={500}
          />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default MooringsPage
