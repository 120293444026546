// REACT
import React from "react"
// MUI
import { makeStyles } from "@material-ui/core/styles"

// STYLES
const useStyles = makeStyles({
  imageBlock: {
    margin: "4rem auto",
  },
})

// COMPONENT FUNCTION
export const ImageBlock = ({ children }) => {
  // STYLE OBJECT
  const classes = useStyles()

  // COMPONENT FUNCTION
  return <div className={classes.imageBlock}>{children}</div>
}
